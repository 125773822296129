import './bootstrap';
import Alpine from 'alpinejs'
import Swiper from 'swiper';

// import Swiper styles
import 'swiper/css';
// import Editor from 'tailwind-editor'

window.Swiper = Swiper
// window.Editor = Editor
window.Alpine = Alpine
Alpine.start()
// let dhtml = ''